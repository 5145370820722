import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Masonry from "react-masonry-css"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import Seo from "./../components/Layout/Seo"
import { InlineVideo, EmbedVideo, YoutubeVideo } from "./../components/Shared"
import LinkedImage from "./../components/LinkedImage"
import Title from "./../components/Title"
import InstagramFeed from "./../components/Instagram/InstagramFeed"
import VerticalTitle from "../components/VerticalTitle"

const Wrapper = styled(motion.section)`
    width: 100%;

    padding: 8rem 1.5rem 1.5rem;

    .my-masonry-grid {
        display: flex;
        margin-left: -1.5rem;
        width: auto;
    }
    .my-masonry-grid_column {
        padding-left: 1.5rem;
        background-clip: padding-box;
    }

    .my-masonry-grid_column > div {
        margin-bottom: 1.5rem;
    }

    ${props => props.theme.above.desktop`
        max-width: ${props => props.theme.breakpoints.extraWide}px;
        margin: 0 auto;
        
        padding: 20rem 3rem 0 3rem;

        .my-masonry-grid {
            margin-left: -3rem;
        }
        .my-masonry-grid_column {
            padding-left: 3rem;
        }

        .my-masonry-grid_column > div {
            margin-bottom: 3rem;
        }
    `}
`

const StyledTitle = styled(Title)`
    margin-bottom: 3rem;
    padding: 0 1.5rem;

    ${props => props.theme.above.desktop`
        margin-bottom: 12rem;
    `}
`

const List = styled.div`
    display: grid;
    grid-gap: 1.5rem;

    ${props => props.theme.above.desktop`
        display: none;
    `}
`

const StyledLinkedImage = styled(LinkedImage)`
    .button {
        display: none;

        right: 3rem;
        bottom: 3rem;
    }

    ${props => props.theme.above.desktop`
        .button {
            display: flex;
        }
    `}
`

const StyledImg = styled(Img)``

const VideoWrapper = styled.div`
    position: relative;

    padding-top: 56.25%;
`

const Item = ({ lang, data }) => {
    if (data.video.document == null) {
        if (data.product_link.document == null && data.image.alt == null) {
            // Placeholder for masonry
            return null
        } else if (
            data.product_link.document == null &&
            data.image.alt != null
        ) {
            return (
                <div style={{ position: "relative" }}>
                    <StyledImg fluid={data.image.fluid} alt={data.image.alt} />
                    <VerticalTitle title={data.image.alt} />
                </div>
            )
        } else {
            return (
                <StyledLinkedImage
                    lang={lang}
                    product={data.product_link.document}
                    image={data.image}
                />
            )
        }
    } else {
        return (
            <VideoWrapper>
                <EmbedVideo
                    lang={lang}
                    data={data.video.document.data}
                    product={data.product_link && data.product_link.document}
                />
                {/* <YoutubeVideo
                    lang={lang}
                    data={data.video.document.data}
                    product={data.product_link && data.product_link.document}
                /> */}
            </VideoWrapper>
        )
    }

    // if (!data.video.document && data.image && !data.product_link) {
    //     if (data.image != null) {
    //         return (
    //             <StyledImg
    //                 fluid={data.image.fluid}
    //                 alt={data.image.alt}
    //             />
    //         )
    //     } else {
    //         return null
    //     }
    // } else if (!data.video.document && data.image && data.product_link) {
    //     return (
    //         <StyledLinkedImage
    //             lang={lang}
    //             product={data.product_link.document}
    //             image={data.image}
    //         />
    //     )
    // } else if (data.video.document) {
    //     return (
    //         <VideoWrapper>
    //             <EmbedVideo
    //                 lang={lang}
    //                 data={data.video.document.data}
    //                 product={data.product_link && data.product_link.document}
    //             />
    //         </VideoWrapper>
    //     )
    // }
}

const InspirationPage = ({
    pageContext: { lang, langSlug },
    data: {
        instagram,
        inspiration: {
            data: {
                page_title,
                seo_title,
                seo_description,
                seo_keywords,
                seo_image,
                content_title,
                content_description,
                gallery,
            },
        },
    },
}) => {
    const [ref, inView] = useInView({
        triggerOnce: false,
    })

    return (
        <>
            <Seo
                title={seo_title}
                description={seo_description.text}
                keywords={seo_keywords}
                image={seo_image}
            />
            <Wrapper
                ref={ref}
                animate={inView ? "after" : "before"}
                variants={{
                    before: {
                        opacity: 0,
                        y: -100,
                    },
                    after: {
                        opacity: 1,
                        y: 0,
                    },
                }}
                transition={{
                    damping: 100,
                    stiffness: 100,
                    ease: "circOut",
                }}
            >
                <StyledTitle
                    title={content_title.text}
                    description={content_description.raw}
                    alignment="center"
                />
                {gallery[0].items.length > 0 && (
                    <Masonry
                        breakpointCols={{
                            default: 3,
                            1000: 2,
                            700: 1,
                        }}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {gallery[0].items.map((item, index) => {
                            return (
                                <Item key={index} lang={langSlug} data={item} />
                            )
                        })}
                    </Masonry>
                )}
                {gallery[0].items.length > 0 && (
                    <List className="mobile">
                        {gallery[0].items.map((data, index) => {
                            return (
                                <Item key={index} lang={langSlug} data={data} />
                            )
                        })}
                    </List>
                )}
            </Wrapper>
            <InstagramFeed copy={instagram} />
        </>
    )
}

export const QUERY_INSPIRATIONPAGE = graphql`
    query InspirationPage($id: String!, $lang: String!) {
        inspiration: prismicInspirationPage(id: { eq: $id }) {
            id
            lang
            ...InspirationPageQuery
        }
        instagram: prismicInstagramComponent(lang: { eq: $lang }) {
            id
            lang
            ...InstagramQuery
        }
    }
`

export default InspirationPage
